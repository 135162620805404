<template>
  <div class="exam-wrap">
    <pageTitle pageTitle="推屏卡片"></pageTitle>
    <div>
      <PushCards scene="material" />
    </div>
  </div>
</template>
<script>
  import PageTitle from '@/components/PageTitle';
  import PushCards from '../../PlatformModule/PushCard/list.vue';
  export default {
    name: 'PushCardMgr',
    components: {
      PageTitle,
      PushCards
    }
  };
</script>
<style lang="less" scoped></style>
